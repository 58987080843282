import React from 'react';
import SM from '../asset/SMhome.jpg'
import AGL from '../asset/aglbook.png'
import WOAB from '../asset/butteryfly.WEBP'
import T from '../asset/troubador.png'
import Ama from '../asset/amazon.png'
import Water from '../asset/waterstones.png'
const Home = () => {

return (

<div>
  <h1 className="title">Welcome</h1>

    <div className="home-container">
   
      <img src={SM} alt="Sue Morris" className="sue-image"/>
        <p className="intro">
          I think I have always known that I would write.<br/><br />
          From childhood and into my late teens, reading, pre-occupied my time. I clearly remember reading Anna Karenina, enchanted by the passion portrayed, the poetry of Blake, exalted through the voice of a child. But an even earlier memory was when, at, perhaps seven years old, reading Heidi and being transported to her world and unfamiliar culture.<br/><br/>
          I have been lucky to have had a long career in teaching English language and literature, which was truly the best job, giving me the opportunity once again, to re-read Shakespeare’s tragedies, listen again to the words of early Ann Duffy, the passion of Browning, Weir, Armitage and Owen. So, becoming a writer seemed a natural move.<br/><br/>
          When I am invariably asked what my books are about – always difficult to capture in a few single sentences. However, for me, books should not over-complicate an idea – but rather create a depth, a complexity sprung from one image and written with the finest of detail. To name just a few, writers like Isabel Allende, Olga Tokarczuk, Anne Enright, Otessa Moshfegh, Natalia Ginzburg, Claire Keegan, Simone de Beauvoir, and Anton Chekhov have all played their part as significant inspirers and influencers.<br/><br/>
          As a musician friend of mine once said, the single note from a violin can erupt an orchestra of sound. An allegory I see as being synonymous with writing.
        </p>
        </div>
        <div className="books-container">
      <div className="book-container">
      <img src={AGL} alt="Anna Gallo's Lily" className="book-image"/>
      <p className="book-date">August 2024</p>
      <p className="book-title">Anna Gallo's Lily</p>
      <p classname="blurb"> Anna Gallo’s Lily is the name and owner of this unusual Bed and Breakfast. Set between the late 1950’s and the mid 1960’s in the landscape of West Yorkshire, the story weaves back and forth returning to the present of 1966.</p>
        <div className="button-container">
       
        <a href="https://troubador.co.uk/bookshop/contemporary/anna-gallo-s-lily" target="_blank" rel="noopener noreferrer">
        <img className="icon" src={T} alt="Anna Gallo's Lily"></img></a>
        <a href="https://www.amazon.co.uk/Anna-Gallos-Lily-Sue-Morris/dp/1805144707" target="_blank" rel="noopener noreferrer">
        <img className="icon" src={Ama} alt="Anna Gallo's Lily"></img></a>
        <a href="https://www.waterstones.com/book/anna-gallos-lily/sue-morris/9781805144700" target="_blank" rel="noopener noreferrer">
        <img className="icon" src={Water} alt="Anna Gallo's Lily"></img></a>
        </div>
       </div>
       <div className="book-container">
        <img src={WOAB} alt="Wings of a Butterfly" className="book-image"/>
      <p className="book-date">28th April 2025</p>
      <p className="book-title">Wings of a Butterfly</p>
      <p classname="blurb"> A love story that’s both romantic and evocative which tackles the complexities of adolescent infatuation, showing the intricate lives of three women searching for independence. It is set in the London art scene inspired by the author's early career as a painter.</p>
        <div className="button-container">
       
        <a href="https://troubador.co.uk/bookshop/contemporary/wings-of-a-butterfly" target="_blank" rel="noopener noreferrer">
        <img className="icon" src={T} alt="Wings of a Butterfly"></img></a>
        <a href="https://www.amazon.co.uk/Wings-Butterfly-Sue-Morris/dp/1836282508" target="_blank" rel="noopener noreferrer">
        <img className="icon" src={Ama} alt="Wings of a Butterfly"></img></a>
        <a href="https://www.waterstones.com/book/wings-of-a-butterfly/sue-morris//9781836282501" target="_blank" rel="noopener noreferrer">
        <img className="icon" src={Water} alt="Wings of a Butterfly"></img></a>
        </div>
    </div>
    </div>
</div>);


};

export default Home;
